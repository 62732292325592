<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-05-25 23:04:06
 * @LastEditTime: 2021-05-27 15:28:22
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="recruit-info">
    <SonTitle title="招聘信息"></SonTitle>
    <div class="table">
      <div class="header">
        <div>职位名称</div>
        <div>工作地点</div>
        <div>招聘人数</div>
        <div>发布日期</div>
        <!-- <div>操作</div> -->
      </div>
      <div class="item" v-for="item in list" :key="item.id">
        <div>{{ item.name }}</div>
        <div>{{ item.addr }}</div>
        <div>{{ item.num }}</div>
        <div>{{ item.update_time.split(" ")[0] }}</div>
        <!-- <div>申请职位</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$api.getRecruit().then(res => {
        this.list = res.data.data
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.table {
  border: 1px solid #000;
  > div {
    padding: 8px;
  }
  .header {
    background: #b7a16f;
    display: flex;
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #000;
    > div {
      flex: 1;
      text-align: center;
    }
  }
  .item {
    display: flex;
    border-bottom: 1px solid #000;
    align-items: center;

    &:nth-child(2n) {
      background: #eeeeee;
    }
    &:last-child {
      border-bottom: none;
    }
    > div {
      flex: 1;
      text-align: center;
      color: #2b2b2b;
    }
  }
}
</style>